
import { defineComponent, ref } from 'vue'
import { Toaster } from '@/common/Toaster'
import { DigInputController } from '@digithia/input/common'
import router from '@/router'
import { __ } from '@/locales'
import { storeAuth, StoreActionsAuth } from '@/store/auth'

export default defineComponent({
  name: `ViewAskResetPassword`,
  components: {},
  props: {},
  emits: [],
  setup() {
    const username = ref('')
    const loading = ref(false)

    const askResetPassword = async (username: string) => {
      loading.value = true
      if (!DigInputController.checkInputsValidity('.ask-password-form')) {
        loading.value = false
        Toaster.toast({
          type: 'warning',
          message: __('invalidForm').toString(),
        })
      } else {
        const res = await storeAuth.dispatch(
          StoreActionsAuth.ASK_RESET_PASSWORD,
          username,
        )
        loading.value = false
        Toaster.toast(res)
        router.push({ name: 'Login' })
      }
    }

    return { username, loading, askResetPassword }
  },
})
